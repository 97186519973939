@import '/src/styles/utilities.scss';

$card-half-height: calc(var(--cards-height, 0) * 0.5);

// Long enough to mask the cards loading and positioning themselves
$fade-in-duration: 1000ms;
$gradient-image: linear-gradient(
  to bottom,
  transparent 70%,
  var(--color-gray-700) 100%
);
$gradient-cards: linear-gradient(
  to bottom,
  var(--color-gray-700) 50%,
  transparent 50%
);

.hero {
  display: grid;
  grid-template-areas:
    'spashImage'
    'ctaText'
    'cardsFull';
  grid-template-rows: 1fr auto auto;

  // Fill the viewport and create extra room for half the cards to fall below
  // the fold
  height: calc(
    100dvh - var(--header-height, $height-nav-mobile) + $card-half-height
  );

  // Measuring the cards takes a moment, this makes resize feel nicer
  transition: height 0.15s ease-in-out;

  // Speaking of cards taking a moment, this fade eases that
  // and makes it all feel more cohesive
  animation: fade-in $fade-in-duration ease-in;

  @include media-breakpoint-up(lg) {
    // This uses named grid lines to better describe the layout, but we need to
    // specify both the grid-row and grid-column when we do this. This is used
    // to assign rows to multiple/overlapping areas to create a more complex
    // layout.
    // https://ishadeed.com/article/css-grid-area/#intro
    grid-template-areas:
      'spashImage'
      'ctaText'
      'cardsTopHalf'
      'cardsBottomHalf';
    grid-template-rows: 1fr auto $card-half-height auto;
    grid-template-columns: [heroColumnStart] 1fr [heroColumnEnd];
    height: calc(
      100dvh - var(--header-height, $height-nav-desktop) + $card-half-height
    );

    // Special max height because filling the viewport is too much on desktop
    // Let the hero fill 730px tall, plus overhang part of the cards
    max-height: calc(730px + $card-half-height);
  }
}

.hero__image {
  grid-area: spashImage;
  width: 100%;
  height: 100%;
  background: $gradient-image, var(--background-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @include media-breakpoint-up(lg) {
    grid-row: spashImage / cardsTopHalf;
    grid-column: heroColumnStart / heroColumnEnd;
  }
}

.hero__copy {
  grid-area: ctaText;
  padding-top: $space-20;

  // Sneaky way to prevent subpixel rounding from causing a gap between the
  // image and the text, which is visible because of the background color
  margin-top: -1px;
  color: var(--color-white);
  text-shadow: 0 0 6px var(--color-black);
  background-color: var(--color-gray-700);

  @include media-breakpoint-up(lg) {
    grid-row: ctaText;
    grid-column: heroColumnStart / heroColumnEnd;
    margin-top: unset;
    background-color: transparent;
  }
}

.hero__description {
  @include apply-display-styles('s2');

  margin-bottom: $space-responsive-20;
}

.hero__cards {
  grid-area: cardsFull;
  color: var(--color-white);
  background: $gradient-cards;

  @include media-breakpoint-up(lg) {
    grid-row: cardsTopHalf / cardsBottomHalf;
    grid-column: heroColumnStart / heroColumnEnd;
    background: none;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  // Give a little extra delay so the fade effect feels quicker than it is
  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
